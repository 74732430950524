import { watch, ref, isRef } from "vue";
import cloneDeep from "lodash/cloneDeep";
import type { Ref } from "vue";
import type {
  PreFiltrationFormI,
  PreFiltrationFormServiceI,
  PreFiltrationFormContractI,
  PreFiltrationFormClientsI,
} from "@/utils/getters/defaultFilters";
import {
  getDefaultPreFiltrationForm,
  getDefaultPreFiltrationFormService,
  getDefaultPreFiltrationFormContract,
  getDefaultPreFiltrationFormApp,
  getDefaultPreFiltrationFormClients, getDefaultPreFiltrationFormPromos,
} from "@/utils/getters/defaultFilters";
import { Tabs } from "@/router/tabs";

export function getTab(name: string) {
  switch (name) {
    case Tabs.Search:
      return 'search';
    case Tabs.Applications.Main:
    case Tabs.Applications.Bargaining:
    case Tabs.Applications.Participation:
    case Tabs.Applications.Calculation:
    case 'apps':
      return 'apps';
    case Tabs.Contracts.Main:
    case Tabs.Contracts.Completed:
    case Tabs.Contracts.Conclusion:
    case Tabs.Contracts.Execution:
    case 'contracts':
      return 'contracts';
    case Tabs.NotificationHistory:
    case 'history':
      return 'history';
    case Tabs.Services:
    case 'services':
      return 'services';
    case Tabs.AdminPages.PageTariffChanger:
    case 'clients':
      return 'clients';
    case 'promos':
      return 'promos';
  }
}

export function useFiltersComponent<T>(
  modelValue: Ref<T>,
  isVisible: Ref<boolean> | boolean,
  filterNames: string[],
  emit: any,
  routeName: string,
) {

  const tab = getTab(routeName)

  const filters = ref<T>(cloneDeep(modelValue.value))

  const defaultFilters : PreFiltrationFormI = getDefaultPreFiltrationForm()
  const defaultAppFilters : PreFiltrationFormI = getDefaultPreFiltrationFormApp()
  const defaultContractFilters : PreFiltrationFormContractI = getDefaultPreFiltrationFormContract()
  const defaultServiceFilters : PreFiltrationFormServiceI = getDefaultPreFiltrationFormService()
  const defaultClientsFilters : PreFiltrationFormClientsI = getDefaultPreFiltrationFormClients()
  const defaultPromoFilters = getDefaultPreFiltrationFormPromos()
  const defaultHistoryFilters = {  notificationTypes: [] }

  watch(modelValue, (newModelValue: PreFiltrationFormI) => {
    filters.value = cloneDeep(newModelValue);
  }, { immediate: true, deep: true });

  if (isRef(isVisible)) {
    watch(isVisible, (newValue: boolean) => {
      newValue ? filters.value = cloneDeep(modelValue.value) : cleanFilter()
    });
  }

  function cancel() {
    filters.value = cloneDeep(modelValue.value);
    emit('close')
  }

  function getDefaultValue(tab: 'search'|'apps'|'contracts'|'services'|'history'|'clients'|'promos', key: any) {
    switch(tab) {
      case 'search': return defaultFilters[key];
      case 'apps': return defaultAppFilters[key];
      case 'contracts': return defaultContractFilters[key];
      case 'services': return defaultServiceFilters[key];
      case 'history': return defaultHistoryFilters[key];
      case 'clients': return defaultClientsFilters[key];
      case 'promos': return defaultPromoFilters[key];
    }
  }

  function cleanFilterByName(key: string) {
    filters.value[key] = cloneDeep(getDefaultValue(tab, key));
  }

  function cleanFilter() {
    for (const key of filterNames) {
      filters.value[key] = cloneDeep(getDefaultValue(tab, key));
    }
  }

  function applyFilters(value?: any) {
    emit('update:modelValue', cloneDeep(value ? value : filters.value))
    emit('close')
    emit('apply')
  }

  return {
    filters,
    cancel,
    cleanFilter,
    cleanFilterByName,
    applyFilters,
  }
}
